// 
// Buttons
// 
.btn:hover,
.btn:focus,
.btn:active {
    box-shadow: none !important;
}

button {
    cursor: pointer;
}

.btn {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 40px;
    color: $dark;
    border: 0px;
    outline: 0px;
    box-shadow: 0px;
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border-radius: 0px;
    [class^="icon"] {
        margin-left: 10px;
        font-size: 10px;
        position: relative;
        left: 0;
        transition: all 0.25s;
    }
    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        color: $primary;
        [class^="icon"] {
            left: 10px;
        }
    }
}

.bnt-ic-left {
    [class^="icon"] {
        margin-right: 10px;
        margin-left: 0;
    }
    &:hover,
    &:focus,
    &:active {
        [class^="icon"] {
            left: -10px;
        }
    }
}