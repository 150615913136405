// colors
$dark:             #000;
$white:            #fff;
$text-color:       #515050;
$primary:          #a0a074;
$secoundary:       #e1e1ca;
$gray-100:         #f0f0ef;
$gray-200:         #e5e5e5;
$gray-300:         #d8d4ba;
$gray-400:         #918f8f;
$gray-500:         #9f987a;
$gray-600:         #9d9d9d;





