// 
// Reservation
// 

.reservation{
    position: absolute;
    z-index: 44;
    right: 10px;
    top: 10px;
    background-color: $dark;
    padding: 10px;
    .reservation-title{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secoundary;
        border: 0px;
        outline: 0px;
        padding: 10px 15px;
        font-size: 16px;
        width: 100%;
        [class^="icon"]{
            font-size: 16px;
            margin-right: 10px;
        }
    }
    .reservation-body{
        padding-top: 10px;
        position: relative;
        display: flex;
        .day{
            flex: 0 0 50%;
            padding: 0 10px;
        }
        &:before{
            content: "";
            position: absolute;
            top: 42px;
            height: 40px;
            width: 1px;
            left: 50%;
            background-color: rgba( $white, 0.3 );
        }
    }
    .day{
        text-align: center;
        position: relative;
        
        .day-title{
            text-transform: uppercase;
            color: rgba( $white, 0.3 );
            font-size: 16px;
            white-space: nowrap;
        }
        .day-number{
            font-weight: 600;
            font-size: 52px;
            line-height: 1;
            color: $white;
        }
        .day-bottom{
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba( $white, 0.3 );
            font-size: 14px;
            position: relative;
            .day-month{
                margin-right: 4px;
            }
        }

        input{
            width: 100%;
            opacity: 0;
            height: 1px;
            padding: 0;
            margin: 0;
        }
    }
    .day-start{
        input{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    .day-end{
        input{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.reservation-v2 {
    margin: 40px 0;
    .reservation-body {
        background-color: rgba(0, 0, 0, 0.4);
        display: inline-flex;
        padding: 5px;
        justify-content: center;
    }

    .reservation-input-holder {
        padding: 10px 10px;
    }

    .reservation-input {
        background-color: $white;
        width: 210px;
        height: 52px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        padding-left: 20px;

        label, i {
            pointer-events: none;
        }

        label {
            color: $black;
            margin: 0;
            font-size: 16px;
            position: absolute;
            left: 0;
            right: 40px;
            top: 0;
            bottom: 0;
            background-color: $white;
            padding: 15px 0px 10px 20px;
            z-index: 55;
            text-align: left;
        }

        i {
            color: $black; 
            font-size: 16px;
            position: absolute;
            z-index: 44;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        input{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 10px;
            padding-left: 20px;
        }
    }

    .reservation-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secoundary;
        border: 0px;
        outline: 0px;
        padding: 10px 15px;
        font-size: 16px;
    }
}

// datapicker
.ui-datepicker{
    z-index: 89;
}
.ui-datepicker-header{
    background-color: $secoundary;
}

.ui-state-default, .ui-widget-content .ui-state-default{
    background-color: $secoundary;
}

.ui-state-active, .ui-widget-content .ui-state-active{
    border-color: $dark;
    color: $dark;
}

.ui-datepicker .ui-icon-circle-triangle-e{
    background-image: url(../img/svg/arrow-right.svg);
    background-position: 50%;
    background-size: 100%;
}

.ui-datepicker .ui-datepicker-next{
    top: 2px!important;
    right: 2px!important;
    cursor: pointer;
    border:0px!important;
    background-color: transparent!important;
}

.ui-datepicker .ui-icon-circle-triangle-w{
    background-image: url(../img/svg/arrow-left.svg);
    background-position: 50%;
    background-size: 100%;
}

.ui-datepicker .ui-datepicker-prev{
    top: 2px!important;
    left: 2px!important;
    cursor: pointer;
    border:0px!important;
    background-color: transparent!important;
}


@include media-breakpoint-down(md) {
    .reservation-v2 {
        margin: 0;
        padding: 20px;

        .reservation-body {
            padding: 5px 0;
        }
        .reservation-body {
           flex-direction: column;
           display: flex;
        }

        .reservation-input-holder {
            padding: 5px 10px;
        }
    
        .reservation-input {
            width: 100%;
        }
    }
}


@include media-breakpoint-down(sm) {
    .reservation{
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        z-index: 89;
    }
}