// 
// List
// 
ul,
ol {
    @extend .list-unstyled;
}

// list base
ul.list-base {
    font-size: 15px;
    li {
        position: relative;
        padding-left: 10px;
        &:before {
            content: "-";
            position: absolute;
            left: 0;
        }
    }
}

// list bullet
ul.list-bullet {
    font-size: 14px;
    li {
        position: relative;
        padding-left: 15px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            width: 8px;
            height: 8px;
            border: 2px solid $dark;
            border-radius: 50%;
        }
    }
}

.list-download-container {
    margin: 60px 0px;
}

.list-download-title {
    margin-bottom: 30px;
}

ul.list-download {
    list-style: none;
    padding: 0;
   
    .download-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray-200;
        padding: 14px 0;
    }
    .download-name{
        display: flex;
        align-items: center;
    }
    .download-link{
        flex-shrink: 0;
        padding-left: 20px;
        text-transform: uppercase;
        font-size: 12px;
        color: $dark;
        font-weight: 400;
    }

    .download-link:hover{
        color: $primary;
    }
}


@include media-breakpoint-down(md) {
    .list-download-container {
        margin: 25px 0;
    }
    .list-download-title {
        margin-bottom: 20px;
    }
}