// 
// Aos
// 
[data-aos="offer-animation"] {
    @media screen and (min-width: 992px) {
        .aos-image {
            opacity: 0;
            transform: translateX(100px);
            transition: all 0.45s;
        }
        .aos-content {
            transform: translateX(-50px);
            opacity: 0;
            transition: all 0.3s 0.25s;
        }
        &.aos-animate {
            .aos-image {
                opacity: 1;
                transform: translateX(0px);
                opacity: 1;
            }
            .aos-content {
                opacity: 1;
                transform: translateX(0px);
            }
        }
    }
}

[data-aos="offer-food-animation"] {
    @media screen and (min-width: 992px) {
        .offer-image {
            opacity: 0;
            transform: translateX(100px);
            opacity: 0;
            transition: all 0.45s;
        }
        .offer-content {
            opacity: 0;
            transform: translateX(-50px);
            opacity: 0;
            transition: all 0.3s 0.25s;
        }
        &.aos-animate {
            .offer-image {
                opacity: 1;
                transform: translateX(0px);
                opacity: 1;
            }
            .offer-content {
                opacity: 1;
                transform: translateX(0px);
            }
        }
    }
}


[data-aos="history-animation"] {
    @media screen and (min-width: 992px) {
        &:before{
            height: 0px!important;
            transition: all 0.5s;
        }
        .history-item-image, .history-item-text{
            opacity: 0;
            transform: translateY(100px);
            transition: all 0.45s;
        }
        &.aos-animate {
            &:before{
                height: 80px!important;
            }
            .history-item-image, .history-item-text{
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
}