// 
// Modal
// 
.modal-content {
    border-radius: 0px;
    .modal-header,
    .modal-footer {
        border-radius: 0px;
    }
    .close {
        color: $dark;
        opacity: 1;
        transition: all 0.3s;
        &:hover {
            color: $primary;
        }
    }
}

.modal-expand {
    .modal-content {
        background-color: transparent;
        border:0px;
    }
    .close {
        text-shadow: none;
        opacity: 1;
        font-size: 18px;
        text-align: right;
        padding-bottom: 8px;
        color: $white;
    }

    .modal-image {
        width: 100%;
        height: 100%;
    }
}

.modal-xl {
    @include media-breakpoint-up(lg) {
        max-width: 800px;
    }

    // @include media-breakpoint-up(xl) {
    //     max-width: 1000px;
    // }
}
