//
// FORM CONTROL
//
.form-control {
    border-radius: 0px;
    font-size: 15px;
    padding: 18px 30px;
    height: auto;
    font-weight: 300;
    color: $text-color;
}

textarea.form-control {
    height: auto;
    line-height: 1.3;
}

.form-control:focus {
    box-shadow: none;
    border-color: $primary;
}

//
// FORM GROUP
//
.form-group {
    margin-bottom: 20px;
}

.form-group-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
        flex: 0 0 auto;
        margin-left: 20px;
    }
}

//
// PLACEHOLDERS
//
input ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba( $text-color, 0.6);
}

input ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba( $text-color, 0.6);
}

input :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba( $text-color, 0.6);
}

input :-moz-placeholder {
    /* Firefox 18- */
    color: rgba( $text-color, 0.6);
}

//
// CUSTOM RADIO, CUSTOM CHECKBOXES
//
.custom-control {
    margin: 30px 0;
    padding-left: 45px;
    min-height: 30px;
    padding-top: 6px;
    a {
        color: $primary;
        text-decoration: underline;
        font-size: 13px;
        transition: all 0.3s;
        &:hover {
            color: $dark;
        }
    }
}

.custom-control-label {
    font-size: 13px;
}

.custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-label::after {
    width: 30px;
    height: 30px;
    border-radius: 0px;
}

.custom-control-label::before {
    background-color: transparent;
    border: 1px solid #ced4da;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-color: $primary;
    border: 1px solid $primary;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

//
// VALIDATION
//
.parslay-danger {
    display: none;
}

.form-control.form-control-danger {
    border-color: red;
}

.form-group.has-danger {
    label:before {
        border-color: red;
    }
}

@include media-breakpoint-down(md) {
    .form-group-flex {
        display: block;
    }
}