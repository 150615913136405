// 
// page: contact
// 
ul.contact-data-list {
    padding: 0;
    margin: 0 -20px;
    margin-bottom: 35px;
    display: flex;
    li {
        flex: 0 0 33.33333%;
        border-right: 1px solid $secoundary;
        padding: 0 4%;
        position: relative;
    }
    li:first-child{
        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: -1px;
            top: 0;
            bottom: 0;
            border:2px solid $secoundary;
        }
    }
    li:last-child {
        border: 0px;
    }
    
}

.contact-data-row {
    display: block;
    padding: 20px 0;
    margin: 20px 0;
    margin-left: -15px;
    margin-right: -15px;
    .contact-col {
        padding: 15px 15px;
        p {
            margin: 0;
        }
    }
}

.form-title {
    margin-bottom: 50px;
}

@include media-breakpoint-down(md) {
    ul.contact-data-list{
        display: block;
        margin-left: 0;
        margin-right: 0;
        li{
            padding-left: 0;
            padding-right: 0;
            border:0px;
            border-bottom: 1px solid $gray-200;
            &:after{
                content: none!important;
            }
            .contact-col br{
                display: none;
            }
        }
    }
    .contact-data-row {
        margin: 0;
        display: block;
        .contact-col{
            padding: 0px;
        }
    }
    ul.contact-data-list li:first-child .contact-data-row .contact-col:first-of-type{
        padding-left: 0px;
    }

    ul.contact-data-list li:first-child h6{
        padding-top: 0;
    }
}

@include media-breakpoint-down(sm) {}