body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Lato', sans-serif;
    color: $text-color;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    color: $dark;
}

h1,
.h1 {
    font-size: 72px;
}

h2,
.h2 {
    font-size: 48px;
}

h3,
.h3 {
    font-size: 30px;
}

h4,
.h4 {
    font-size: 26px;
}

h5,
.h5 {
    font-size: 20px;
}

h6,
.h6 {
    font-size: 18px;
}

p {
    font-size: 15px;
    font-weight: 400;
}

.lead {
    font-size: 18px;
    font-weight: 500;
}

a,
a:hover,
a:focus,
button,
button:focus,
button:hover {
    text-decoration: none;
    outline: none;
}

a {
    color: $dark;
    transition: all 0.35s;
}

a:hover,
a:focus {
    color: $primary;
    
}

@include media-breakpoint-down(xl) {
    h1,
    .h1 {
        font-size: 62px;
    }
    h2,
    .h2 {
        font-size: 40px;
    }
    h3,
    .h3 {
        font-size: 26px;
    }
    h4,
    .h4 {
        font-size: 22px;
    }
    h5,
    .h5 {
        font-size: 20px;
    }
    h6,
    .h6 {
        font-size: 18px;
    }
}

@include media-breakpoint-down(lg) {}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(sm) {
    h1,
    .h1 {
        font-size: 54px;
    }
    h2,
    .h2 {
        font-size: 32px;
    }
    h3,
    .h3 {
        font-size: 24px;
    }
    h4,
    .h4 {
        font-size: 20px;
    }
    h5,
    .h5 {
        font-size: 18px;
    }
    h6,
    .h6 {
        font-size: 16px;
    }
}