// 
// List equipment
// 
ul.list-equipment {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 40px;
    li {
        flex: 0 0 25%;
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .equipment-icon {
        flex: 0 0 50px;
        text-align: center;
        [class^="icon"] {
            font-size: 34px
        }
    }
    .equipment-text {
        p {
            margin: 0;
            padding-left: 20px;
            max-width: 160px;
            font-size: 15px;
            line-height: 16px;
        }
    }
}


@include media-breakpoint-down(xl) {
    ul.list-equipment {
        li {
            flex: 0 0 33.333%;
            padding: 15px;
        }
    }
}

@include media-breakpoint-down(md) {
    ul.list-equipment {
        li {
            flex: 0 0 50%;
            padding: 10px;
        }
        .equipment-text p{
            padding-left: 5px;
            font-size: 13px;
        }
    }
}