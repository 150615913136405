// 
// Page
//
.page {
    padding: 99px 40px 0px 40px;
    overflow: hidden;
    position: relative;
}

.page-title {
    padding: 35px 0;
    padding-bottom: 60px;
    .breadcrumb {
        margin-bottom: 20px;
    }
    h2 {
        text-transform: uppercase;
    }
}

.page-title-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .btn{
        padding-left: 0;
    }
}

.page-subtitle{
    margin: 40px 0;
    span{
        margin-left: 10px;
        opacity: 0.4;
        font-size: 18px;
    }
}

.subtitle-border-top{
    position: relative;
    padding-top: 40px;
    margin-top: 40px;
    &:before{
        content: "";
        position: absolute;
        left: -15px;
        right: -15px;
        height: 1px;
        top: 0;
        background-color: $gray-200;
    }
}

.content-bg {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    .swiper-nav-prev, .swiper-nav-next{
        top: 50%;
        transform: translateY(-50%);
    }
    .swiper-nav-prev {
        left: 0px;
    }
    .swiper-nav-next {
        right: 0px;
    }
    .page-subtitle:first-of-type{
        margin-top: 0;
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $gray-100;
    }
}

.content-bg-top {
    padding-top: 0;
    &:before {
        top: 65px;
    }
}

.content-bg-top-lg {
    padding-top: 0;
    &:before {
        top: 100px;
    }
}

.content-bg-top-xl {
    padding-top: 0;
    &:before {
        top: 140px;
    }
}

// main content
.page-main-image {
    position: relative;
    .swiper-nav-prev, .swiper-nav-next{
        top: 50%;
        transform: translateY(-50%);
    }
    .swiper-nav-prev {
        left: 0px;
    }
    .swiper-nav-next {
        right: 0px;
    }
}

.page-main-content {
    .text-hero {
        position: absolute;
        left: -20%;
        top: 60px;
        font-size: 180px;
        opacity: 0.05;
        font-weight: 700;
        line-height: 0;
    }
    p {
        padding-left: 70px;
    }
    h4 {
        margin: 50px 0;
    }
    h6 {
        margin: 40px 0;
    }
}


@include media-breakpoint-down(xl) {}

@include media-breakpoint-down(lg) {
    .page {
        padding: 65px 40px 0px 40px;
    }
}

@include media-breakpoint-down(md) {
    .page {
        padding: 65px 0px 0px 0px;
    }
    
    .page-title{
        padding-bottom: 10px;
    }
    .page-subtitle{
        margin: 20px 0;
        span{
            display: block;
            margin-left: 0;
        }
    }
    .page-main-content{
        h4:first-of-type{
            margin-top: 0;
        }
        h2, h3, h4, h5, h6{
            margin: 20px 0;
        }
        .text-hero{
            padding-top: 15px;
            position: static;
            font-size: 36px;
            opacity: 0.5;
            line-height: 1.3;
        }
        p{
            padding-left: 0;
        }
    }
}