//
// Offer item
//
.offer-item {
    display: flex;
    align-items: center;
    .offer-content {
        position: relative;
        z-index: 3;
        background-color: $white;
        padding: 50px;
        border: 1px solid $gray-300;
        h2 {
            margin-bottom: 35px;
            font-size: 72px;
        }
        h3,
        h4,
        h5,
        h6,
        h6,
        ul {
            margin-bottom: 40px;
        }
        .btn {
            padding-left: 0;
        }
    }
    .offer-image {
        position: relative;
        z-index: 2;
    }
}

// offer food
ul.list-offer-food {
    >li {
        margin-bottom: 80px;
    }
    li {
        .offer-item {
            display: flex;
            align-items: center;
            .offer-content {
                flex: 0 0 58.33333%;
                border: 0px;
                padding: 6% 10%;
                margin-left: -4%;
            }
            .offer-image {
                flex: 0 0 33.33333%;
                margin-left: 8.33333%;
            }
        }
    }
    li:nth-child(odd) {
        .offer-item {
            .offer-content {
                order: 1;
                margin-left: 0;
            }
            .offer-image {
                order: 2;
                margin-left: -4%;
            }
        }
    }
}


// List offer
ul.list-offer {
    margin: 0;
    margin-top: 50px;
    >li {
        margin-bottom: 80px;
    }
    li {
        .offer-item {
            display: flex;
            align-items: center;
            .offer-content {
                flex: 0 0 50%;
                padding: 2.5% 10%;
                transform: translateX(0%);
                background-color: transparent;
                border: 0px;
                position: relative;
                z-index: 3;
                transition: all 0.35s;
                h3,
                h4,
                h5,
                h6,
                h6 {
                    margin-bottom: 20px;
                }
            }
            .offer-image {
                flex: 0 0 50%;
                position: relative;
                z-index: 2;
                transform: translateX(0%);
                transition: all 0.35s;
            }
        }
    }
    li:nth-child(even) {
        .offer-item {
            .offer-content {
                order: 1; 
            }
            .offer-image {
                order: 2;
            }
        }
    }


    @media screen and (min-width: 992px) {
        li .offer-item-hover{
            .offer-content{
                transform: translateX(-16%);
                background-color: $white;
                border: 1px solid $gray-300;
            }
            .offer-image {
                transform: translateX(16%);
            }
        }
    
        li:nth-child(even) {
            .offer-item-hover {
                .offer-content {
                    transform: translateX(16%);
                }
                .offer-image {
                    transform: translateX(-16%);
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    // offer item
    .offer-item {
        .offer-content {
            h2 {
                font-size: 54px;
                margin-bottom: 25px;
            }
        }
    }

    // offer food
    ul.list-offer-food {
        li {
            .offer-item {
                .offer-content {
                    padding: 4% 5%;
                    h3,
                    h4,
                    h5,
                    h6 {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    // offer food
    ul.list-offer-food {
        li {
            .offer-item {
                .offer-content {
                    padding: 4% 5%;
                    h3,
                    h4,
                    h5,
                    h6 {
                        margin-bottom: 15px;
                    }
                    h6 {
                        font-size: 14px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    // offer
    ul.list-offer {
        li:nth-child(4),
        li:nth-child(8),
        li:nth-child(12),
        li:nth-child(16) {
            .offer-item {
                .offer-content {
                    margin-left: 5%;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    // offer item
    .offer-item {
        .image-holder {
            padding-bottom: 52.5%;
        }
        .offer-content {
            h2 {
                font-size: 36px;
                margin-bottom: 20px;
            }
        }
    }
    // offer food
    ul.list-offer-food {
        >li {
            margin-bottom: 30px;
        }
        li {
            .offer-item {
                .offer-content {
                    padding: 5% 10%;
                    margin-left: 0!important;
                    h3,
                    h4,
                    h5,
                    h6 {
                        margin-bottom: 20px;
                    }
                    h6 {
                        font-size: 18px;
                    }
                    p {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    ul.list-offer-food {
        li,
        li:nth-child(odd) {
            .offer-item {
                flex-direction: column;
                .offer-content {
                    order: 2;
                    width: 100%;
                }
                .offer-image {
                    order: 1;
                    margin-left: 0%;
                    width: 100%;
                }
            }
        }
    }

    // offer
    ul.list-offer {
        >li {
            margin-bottom: 30px;
        }
        li,
        li:nth-child(odd) {
            .offer-item {
                flex-direction: column;
                .offer-content {
                    order: 2 !important;
                    width: 100%;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    background-color: $white;
                    border: 1px solid $gray-300;
                    padding: 5% 10%;
                }
                .offer-image {
                    order: 1 !important;
                    margin-left: 0%;
                    width: 100%;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }
        }
    }
}
