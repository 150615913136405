//
// Article
//
article,
.article {
    margin: 50px 0;
    h2,
    h3,
    h4,
    h5,
    h6,
    img {
        margin: 40px 0;
    }
    p {
        margin: 30px 0;
        padding-left: 40px;
    }

    .article-image-full {
        display: block;
        width: 100%;
    }

    .article-image-center {
        text-align: center;
    }

    .article-image-left, .article-image-right {
        display: flex;
        align-items: center;

        .article-image-media,
        .article-image-content {
            flex: 0 0 50%;

        }

        .article-image-content {
            padding: 0px 20px;
            p {
                padding: 0;
            }
        }
    }

    .article-image-left {
        .article-image-content {
            order: 2;
        }
        .article-image-media {
            order: 1;
        }
    }
}

.bundle-description{
    h2, h3, h4, h5, h6, ul{
        margin-bottom: 40px;
    }
}
.bundle-price{
    background-color: $secoundary;
    font-size: 18px;
    padding: 10px 35px;
    text-align: center;
    top: 30px;
}


@include media-breakpoint-down(md) {
    .bundle-description{
        padding-top: 30px;
        h2, h3, h4, h5, h6, ul{
            margin-bottom: 20px;
        }
    }

    article,
    .article {
        .article-image-left, .article-image-right {
            display: block;

            .article-image-content {
                padding: 0px;

            }
        }
    }
}


@include media-breakpoint-down(sm) {
    article,
    .article {
        margin: 25px 0;
        h2,
        h3,
        h4,
        h5,
        h6,
        img {
            margin: 25px 0;
        }
        p {
            margin: 20px 0;
            padding-left: 0px;
        }
    }
}