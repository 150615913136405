// 
// Tabs
// 
.nav-tabs {
    border-bottom: 1px solid $gray-200;
    margin-left: -30px;
    margin-right: -30px;
}

.nav-tabs .nav-link {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 20px;
        bottom: 20px;
        width: 1px;
        background-color: $gray-200;
    }
}

.nav-tabs .nav-link {
    border-radius: 0px;
    border: 0px;
    font-size: 18px;
    font-weight: 700;
    opacity: 0.4;
    padding: 20px 30px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    opacity: 1;
    background-color: transparent;
}

// tab content
.tab-content {
    padding: 15px 0;
    .text-center img {
        margin: 30px 0;
    }
}

@include media-breakpoint-down(md) {
    .nav-tabs{
        margin-left: 0;
        margin-right: 0;
    }
}

@include media-breakpoint-down(sm) {
    .nav-tabs{
       display: block;
    }

    .nav-tabs .nav-link {
        position: relative;
        
    }

    .nav-tabs .nav-link {
        border-radius: 0px;
        border: 0px;
        font-size: 14px;
        font-weight: 700;
        opacity: 0.4;
        padding: 10px 15px;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: auto;
            bottom: 0px;
            width: 100%;
            height: 1px;
            background-color: $gray-200;
        }
    }
}