// 
// Dropdown
// 

.header-lang .dropdown-toggle{
    padding: 10px;
}

.dropdown-menu{
    min-width: auto;
    padding: 0;
    margin: 0;
    border-radius: 0px;
}
.dropdown-item{
    padding: 8px 10px;
    border-bottom: 1px solid $gray-200;
    &:hover, &:focus{
        background-color: $secoundary;
    }
    
}
