// 
// Cards
// 
.card {
    border-radius: 0px;
    background-color: transparent;
    border: 0px;
    box-shadow: none;
    transition: all 0.35s;
    position: relative;
    .card-body {
        padding: 40px 20px;
    }
    .card-title {
        text-align: center;
    }
    .card-info {
        position: absolute;
        z-index: 10;
        background-color: rgba( $dark, 0.8);
        width: 365px;
        height: 200px;
        padding: 15px 30px;
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        .btn {
            color: $white;
        }
        .info-icon {
            position: absolute;
            font-size: 56px;
            color: rgba( $white, 0.4);
            transition: all 0.3s;
        }
        h4 {
            font-size: 24px;
            color: $white;
            transition: all 0.3s;
        }
        .info-btn {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 40px;
            text-align: center;
        }
    }
    .card-info-left {
        left: -160px;
        bottom: -100px;
        .info-icon {
            left: 30px;
            top: 15px;
        }
        .info-title {
            padding-left: 155px;
        }
    }
    .card-info-right {
        right: -160px;
        bottom: -100px;
        .info-icon {
            right: 30px;
            top: 15px;
        }
        .info-title {
            padding-right: 155px;
        }
    }

    .card-info-hover{
        display: block;
    }

    
}

.card-info-hover:hover{
    .card-info{
        .btn, h4, .info-icon{
            color: $primary;
        }
    }
}

.card-bundle-list {
    margin-right: -10px;
        margin-left: -10px;
        > .col,
        > [class*="col-"] {
          padding-right: 10px;
          padding-left: 10px;
    }
}

// card bundle
.card-bundle {
    border: 2px solid $secoundary;
    background-color: $white;
    .card-title {
        text-align: left;
        padding: 20px 0;
        padding-top: 5px;
        border-bottom: 1px solid $gray-200;
    }
    .card-body {
        padding: 15px;
    }
    .card-price {
        background-color: $secoundary;
        padding: 12px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
    }

    .card-price-value {
        font-weight: 600;
        font-size: 15px;
    }

    .card-price-gray {
        background-color: $gray-100;
    }
}

.card-bundle-list-info {
    margin-top: 35px;
    text-align: center;
    p {
        margin: 0;
        font-size: 13px;
    }
}


@include media-breakpoint-down(xl) {
    .card {
        .card-info {
            width: 315px;
            height: 180px;
            padding: 15px 20px;
            .info-icon {
                font-size: 46px;
                left: 20px;
            }
        }
        .card-info-left {
            left: -60px;
            bottom: -40px;
            .info-title {
                padding-left: 90px;
            }
        }
        .card-info-right {
            right: -60px;
            bottom: -40px;
            .info-icon {
                left: auto;
                right: 20px;
            }
            .info-title {
                padding-right: 90px;
            }
        }
    }

    .card-bundle-list {
        margin-right: -5px;
            margin-left: -5px;
            > .col,
            > [class*="col-"] {
              padding-right: 5px;
              padding-left: 5px;
        }
    }

    .card-bundle {
        .card-title {
            padding: 15px 0;
            padding-top: 5px;
            
        }
        .card-body {
            padding: 10px;
        }
        .card-price {
            padding: 12px 10px;
            font-size: 12px;
        }
    
        .card-price-value {
            font-weight: 600;
            font-size: 12px;
        }
    }
}

@include media-breakpoint-down(md) {
    .card {
        .card-info {
            position: relative;
            width: 100%;
            height: auto;
            left: auto;
            right: auto;
            top: auto;
            bottom: 0px;
            .info-icon {
                left: 20px;
                right: auto;
            }
            .info-btn {
                text-align: left;
                margin-top: 15px;
                position: static;
                margin-top: 15px;
            }
        }
        .card-info-left,
        .card-info-right {
            .info-title {
                padding-top: 10px;
                padding-left: 100px;
                padding-right: 20px;
            }
        }
    }
    .card-bundle{
        margin-bottom: 20px;
    }

    .card-info-hover{
        margin-bottom: 25px;
    }
}