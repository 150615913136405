//
// header
//
.header{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    background-color: $white;
    padding: 15px 40px;
    border-bottom: 1px solid transparent;
    transition: all 0.35s;
    .header-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 40px;
    }
    .header-brand{
        width: 115px;
        flex: 0 0 115px;
        img{
            max-width: 115px;
            transition: all 0.35s;
        }
    }
    .header-nav-container {
        flex: 1 1 auto;
        margin: 0 15px;
        transition: all 0.35s;
    }

    .header-group{
        flex: 0 1 auto;
        display: flex;
        align-items: center;
    }

    #toggle-menu{
        display: none;
    }

    ul.header-nav{
        display: flex;
        justify-content: center;
        margin: 0;
        padding-top: 10px;
        transition: all 0.35s;

        > li{
            margin: 0 20px;
            position: relative;
        }
        > li > a{
            font-size: 17px;
            font-weight: 400;
            transition: all 0.35s;
            color: $dark;
        }
        > li > a.current, > li a:hover{
            color: $primary;
        }
    }

    ul.header-contact{
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $gray-100;
        padding-bottom: 10px;
        transition: all 0.35s;

        li{
            margin-bottom: 4px;
            margin-right: 10px;
        }
        li:last-child{
            margin-bottom: 0;
            margin-right: 0;
        }
        li a{
            font-size: 15px;
            display: flex;
            align-items: center;
            [class^="icon"]{
                font-size: 16px;
                width: 40px;
                flex: 0 0 40px;
                line-height: 1;
                text-align: center;
            }
            .icon-smartphone{
                font-size: 24px;
            }
        }

        img {
            max-width: 48px;
        }
    }
    .header-lang{
        margin-left: 15px;
        padding-left: 10px;
        border-left:1px solid $gray-200;
    }

    ul.header-socials {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        li {
            padding: 10px;
            transition: all 0.35s;
        }
        li a {
            font-size: 16px;
        }
    }
}

.header-sticky{
    padding: 10px 40px;
    border-bottom: 1px solid $gray-200;
    .header-brand{
        img{
            max-width: 80px;
        }
    }

    ul.header-contact {
        padding-bottom: 5px;
    }

    ul.header-nav {
        padding-top: 5px;
    }
}


@include media-breakpoint-down(xl) {
    .header{
        .header-row{
            padding-left: 0px;
        }
        ul.header-nav{
            margin-right: 10px;
            > li{
                margin: 0 10px;
            }
            > li > a{
                font-size: 15px;
            }
        }

        ul.header-contact li a{
            font-size: 13px;
            [class^="icon"]{
                font-size: 16px;
            }
        }
    }
}

@media all and (max-width: 1279px){
    .header{
        ul.header-nav{
            margin-right: 5px;
            > li{
                margin: 0 5px;
            }
            > li > a{
                font-size: 13px;
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .header{
        ul.header-nav, .header-group{
            display: none;
        }
        #toggle-menu{
            display: block;
            margin-left: 15px;
        }

        ul.header-contact {
            display: none;
        }
    }

    .header, .header-sticky{
        padding: 10px 15px;
        border-bottom: 1px solid $gray-200;
        .header-brand{
            img{
                max-width: 80px;
            }
        }
    }
}
