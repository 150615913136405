// 
// Swiper nav
// 
.swiper-nav {
    position: absolute;
    z-index: 33;
    top: 50%;
    transform: translateY(-50%);
    color: $dark;
    font-size: 14px;
    background-color: $white;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-align: center;
}

.swiper-nav-prev {
    left: 20px;
}

.swiper-nav-next {
    right: 20px;
}

// swiper bav slide
.nav-slide{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 44;
    .slide-icon {
        color: $dark;
        font-size: 14px;
        background-color: $white;
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        text-align: center;
        position: relative;
        z-index: 55;
    }
    .slide-nav {
        position: absolute;
        white-space: nowrap;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secoundary;
        font-size: 14px;
        color: $dark;
        font-weight: 400;
        min-width: 230px;
        transition: all 0.35s;
        padding: 0 10px;
    }
}

.nav-slide-left{
    &:after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateX(-100%);
        background-color: $white;

    }
    left: 0;
    .slide-nav{
        left: 100%;
        transform: translateX(-100%);
    }
}

.nav-slide-right{
    right: 0;
    &:after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateX(100%);
        background-color: $white;

    }
    .slide-nav{
        right: 100%;
        transform: translateX(100%);
    }
}


.nav-slide:hover{
    .slide-icon{
        color: $primary;
    }
    .slide-nav{
        transform: translateX(0%);
    }
}

// 
// Galery swiper
//
.swiper-gallery {
    margin: 40px 0;
    .swiper-nav-prev,
    .swiper-nav-next {
        transform: translateY(0px);
        top: 220px;
    }
    .swiper-nav-prev {
        left: 0;
    }
    .swiper-nav-next {
        left: 50%;
        transform: translateY(0px) translateX(-100%);
    }
}

// #swiper-gallery {
//     .swiper-slide {
//         width: 25%;
//         transition: all 0.4s;
//         .image-holder {
//             height: 500px;
//             opacity: 0.4;
//             transition: all 0.4s;
//             .image-holder-bg {
//                 transition: all 0.4s;
//                 top: 0px;
//                 bottom: 80px;
//             }
//         }
//     }
//     .swiper-slide-next {
//         .image-holder {
//             .image-holder-bg {
//                 top: 45px;
//                 bottom: 0px;
//             }
//         }
//     }
//     .swiper-slide-active {
//         width: 50%;
//         .image-holder {
//             opacity: 1;
//             .image-holder-bg {
//                 top: 0px;
//                 bottom: 0;
//             }
//         }
//     }
// }


#swiper-gallery {
    position: relative;
    &:before{
        content: "";
        position: absolute;
        z-index: 11;
        top: 0;
        left: 50%;
        width: 25%;
        height: 40px;
        background-color: $white;
    }
    &:after{
        content: "";
        position: absolute;
        z-index: 11;
        bottom: 0;
        right: 0;
        width: 25%;
        height: 80px;
        background-color: $white;
    }
    .swiper-slide {
        width: 25%;
        transition: all 0.4s;
        .image-holder {
            height: 500px;
            opacity: 0.4;
            margin-left: 25%;
            transition: opacity 0.4s;
            z-index: 1;
            .image-holder-bg {
                transition: all 0.4s;
            }
        }
    }
    .swiper-slide-next {
        .image-holder {
            width: 125%;
            z-index: 2;
            margin-left: 0;
        }
    }
    .swiper-slide-active {
        width: 50%;
        .image-holder {
            width: 150%;
            margin-left: 0;
            z-index: 3;
            opacity: 1;
        }
    }
}


@include media-breakpoint-down(md) {
    .swiper-nav {
        font-size: 12px;
        width: 50px;
        height: 50px;
    }

    .nav-slide{
        .slide-icon {
            font-size: 12px;
            width: 50px;
            height: 50px;
            
        }
        .slide-nav {
           font-size: 12px;
           min-width: 160px;
        }
    }

    .swiper-gallery {
        margin: 40px 0;
        .swiper-nav-prev,
        .swiper-nav-next {
            transform: translateY(-50%);
            top: 50%px;
        }
        .swiper-nav-prev {
            left: 0;
        }
        .swiper-nav-next {
            left: auto;
            right: 0;
        }
    }

    #swiper-gallery {
        &:before, &:after{
            content: none;
        }
        .swiper-slide {
            width: 100%;
            transition: all 0.4s;
            .image-holder {
                opacity: 1;
                height: 400px;
                margin-left: 0;
            }
        }
        .swiper-slide-next {
            .image-holder {
                width: 100%;
                margin-left: 0;
            }
        }
        .swiper-slide-active {
            width: 100%;
            .image-holder {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}