// 
// Sections
// 
.section {
    position: relative;
    overflow: hidden;
    margin: 80px 0;
    .section-title {
        text-transform: uppercase;
        margin: 40px 0;
    }
    .section-wrapper {
        padding-left: 120px;
    }
    .section-text {
        margin: 50px 0;
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 40px;
        }
        h6 {
            width: 380px;
            max-width: 100%;
        }
        p {
            padding-left: 20px;
            width: 540px;
            max-width: 100%;
        }
    }
}

.pull {
    margin-left: -80px;
    margin-right: -80px;
    padding-left: 80px;
    padding-right: 80px;
}

// 
// Home hero
// 
// 
.section-hero {
    position: relative;
    .page-hero-wrapper{
        position: relative;
    }
    .image-holder{
        padding-bottom: 0;
        height: 100vh;
        height: calc( 100vh - 99px );
    }

    .section-hero-content {
        position: absolute;
        background-color: rgba( $dark, 0.6);
        z-index: 2;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $white;
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
            color: $white;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-transform: uppercase;
        }
        h2 {
            font-size: 58px;
            letter-spacing: 2px;
        }
        .hero-icon {
            font-size: 70px;
        }
        p {
            width: 680px;
            max-width: 90%;
            margin: 10px auto 0px auto;
            font-size: 24px;
            font-weight: 300;
        }

        .btn {
            border:2px solid $white;
            padding: 8px 18px 8px 12px;
            margin-top: 15px;
        }
    }
}

//
// section about
// 
.section-about {
    padding-top: 100px;
    margin-top: 20px;
    .section-title {
        margin-top: 0;
    }
    .section-wrapper {
        padding-left: 100px;
    }
    .section-text {
        h6 {
            width: 720px;
            max-width: 100%;
        }
    }
    .boxed {
        position: relative;
        z-index: 11;
        margin-right: -15%;
        padding-left: 0;
        box-shadow: none;
    }
    .about-text-bottom {
        padding-left: 80px;
        p {
            border-top: 1px solid $gray-200;
            padding-top: 25px;
            margin-top: 25px;
            font-style: italic;
            font-size: 14px;
        }
    }
    .section-about-bg {
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        top: 0;
        bottom: 0;
        right: 0;
        width: 50%;
    }
}

//
// Section bundle
// 
.section-bundle {
    .section-title {
        text-align: center;
    }
    .swiper-nav-prev, .swiper-nav-next{
        transform: translateY(0px);
        top: 220px;
    }
    .swiper-nav-prev{
        left: 0;
    }
    .swiper-nav-next{
        left: 50%;
        transform: translateY(0px) translateX(-100%);
    }
}

// #swiper-bundle {
//     .swiper-slide {
//         width: 25%;
//         transition: all 0.4s;
//         .image-holder {
//             height: 500px;
//             opacity: 0.4;
//             transition: all 0.4s;
//             .image-holder-bg{
//                 transition: all 0.4s;
//                 top: 0px;
//                 bottom: 80px;
//             }
//         }
//         .boxed-wrapper {
//             padding-left: 10%;
//             padding-right: 10%;
//             margin-top: -85px;
//             position: relative;
//             z-index: 10;
//             opacity: 0;
//             visibility: hidden;
//             transform: translateX(50px);
//             transition: all 0.3s 0.15s;
//         }
//         ul.list-base{
//             margin-top: 40px;
//         }
//     }
//     .swiper-slide-next{
//         .image-holder {
//             .image-holder-bg{
//                 top: 45px;
//                 bottom: 0px;
//             }
//         }
//     }
//     .swiper-slide-active {
//         width: 50%;
//         .image-holder {
//             opacity: 1;
//             .image-holder-bg{
//                 top: 0px;
//                 bottom: 0;
//             }
//         }
//         .boxed-wrapper {
//             opacity: 1;
//             visibility: visible;
//             transform: translateX(0px);
//         }
//     }
// }


#swiper-bundle {
    position: relative;
    &:before{
        content: "";
        position: absolute;
        z-index: 11;
        top: 0;
        left: 50%;
        width: 25%;
        height: 40px;
        background-color: $white;
    }
    &:after{
        content: "";
        position: absolute;
        z-index: 11;
        top: 420px;
        right: 0;
        width: 25%;
        height: 80px;
        background-color: $white;
    }
    .swiper-slide {
        width: 25%;
        transition: all 0.4s;
        .image-holder {
            height: 500px;
            opacity: 0.4;
            margin-left: 25%;
            transition: opacity 0.4s;
            z-index: 1;
            .image-holder-bg{
                transition: all 0.4s;
            }
        }
        .boxed-wrapper {
            width: 130%;
            padding-left: 10%;
            padding-right: 10%;
            margin-top: -85px;
            position: relative;
            z-index: 12;
            opacity: 0;
            visibility: hidden;
            transform: translateX(50px);
            transition: all 0.3s 0.15s;
            .boxed{
                transform: translateY(-3px);
            }
        }
        ul.list-base{
            margin-top: 40px;
        }
    }
    .swiper-slide-next{
        .image-holder {
            width: 125%;
            z-index: 2;
            margin-left: 0;
        }
    }
    .swiper-slide-active {
        width: 50%;
        .image-holder {
            width: 150%;
            margin-left: 0;
            z-index: 3;
            opacity: 1;
        }
        .boxed-wrapper {
            opacity: 1;
            visibility: visible;
            transform: translateX(0px);
        }
    }
}

.disable-offset{
    margin-left: 0!important;
    margin-right: 0!important;
}


@include media-breakpoint-up(lg) {
    #swiper-bundle.swiper-noloop{
        &:before, &:after{
            content: none;
        }
        .swiper-nav{
            display: none;
        }
    
        .swiper-wrapper{
            justify-content: center;
        }
    
        .swiper-slide {
            padding: 10px;
            transition: all 0s;
            .image-holder {
                height: 400px;
                opacity: 1;
                margin-left: 0%!important;
                margin-right: 0%!important;
                width: 100%!important;
                transition: all 0s;
            }
            .boxed-wrapper {
                width: 92%;
                padding-left: 4%;
                padding-right: 4%;
                opacity: 1;
                visibility: visible;
                transform: translateX(0px);
                transition: all 0.3s 0.15s;
                transition: all 0s;
                .boxed{
                    padding: 40px 25px;
                    transform: translateY(-3px);
                }
            }
        }
    }
}




// 
// Section events
// 
.section-events {
    margin-top: -40px;
    position: relative;
    z-index: 10;
    .section-title {
        margin: 50px 0 85px 0px;
        text-align: center;
    }
    .section-text {
        width: 630px;
        max-width: 100%;
        margin: 20px auto;
        p {
            text-align: center;
            padding: 0;
        }
    }
    .content-bg{
        &:before{
            bottom: 25%;
        }
    }
}
// 
// Section food
// 
.section-food{
    margin-bottom: 0;
    .col-lg-4:nth-of-type(2){
        padding-top: 45px;
    }
    .content-bg{
        &:before{
            bottom: 25%;
        }
    }
}

// 
// Parallax
// 
.section-parallax {
    margin-top: 0;
    margin-bottom: 0;
    .paralax-swiper{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.75);
            z-index: 10;
        }
        .swiper-container, .swiper-wrapper{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .image-holder{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding-bottom: 0;
            .image-holder-bg{
                //background-attachment: fixed;
            }
        }
    }
    .section-title {
        text-align: center;
        color: $white;
        margin-bottom: 65px;
        h2 {
            color: inherit;
        }
        [class^="icon"] {
            font-size: 72px;
        }
    }
    ul.list-parallax {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-left: -25px;
        margin-right: -25px;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
            flex: 0 0 33.333%;
            padding-left: 25px;
            padding-right: 25px;
            margin-bottom: 50px;
        }
        .item {
            border: 2px solid $white;
            min-height: 160px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10%;
            transition: all 0.4s 0s;
            h3,
            .btn {
                color: $white;
                transition: all 0.4s 0s;
            }
            .btn {
                padding-left: 0;
                &:hover{
                    color: $primary;
                }
            }
            &:hover{
                border:2px solid $primary;
                h3,
                .btn {
                    color: $primary;
                }
            }
        }
    }
}

.parallax {
    padding: 100px 0px;
    position: relative;
    .parallax-background {
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;
        background-attachment: fixed;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
    .parallax-content {
        position: relative;
        z-index: 11;
    }
}



// 
// section history
// 
.section-history{
    margin-top: 0;
}
.hero-history {
    position: relative;
    background-color: $gray-100;
    padding: 140px 0;
    min-height: 600px;
    .hero-background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        opacity: 0.9;
        background-position: right center;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(to right, $gray-100 0%, $gray-100 51%, transparent 100%);
        }
    }
    .hero-content {
        position: relative;
        z-index: 3;
        p,
        .boxed {
            width: 555px;
            max-width: 70%;
        }
    }
}


@include media-breakpoint-down(xl) {
    .section-hero{
        .section-hero-content {
            h2 {
                font-size: 42px;
            }
            p {
                font-size: 20px;
            }
        }
    }
    // sections
    .pull {
        margin-left: -40px;
        margin-right: -40px;
        padding-left: 40px;
        padding-right: 40px;
    }
    // section about
    .section-about{
        .boxed{
            padding-top: 25px;
            padding-bottom: 20px;
            margin-right: -20%;
        }
        .section-text {
            margin: 15px 0;
            h6{
                margin-bottom: 25px;
            }
        }
        .about-text-bottom p{
            padding-top: 15px;
            margin-top: 15px;
        }
    }
    // section bundle
    #swiper-bundle{
        .swiper-slide .image-holder{
            height: 460px;
        }
        .swiper-slide ul.list-base{
            margin-top: 20px;
        }
    }
    
    // section events
    .section-events{
        padding-left: 60px;
        padding-right: 60px;
        overflow: hidden;
        .section-title{
            margin-top: 20px;
            margin-bottom: 60px;
        }
        .section-text{
            padding-top: 60px;
        }
        .content-bg{
            padding-bottom: 0;
        }
    }
}


@include media-breakpoint-down(lg) {
    .parallax{
        .parallax-background{
            background-attachment: scroll;
        }
    }

    .section-hero {
        .image-holder{
            height: 100vh;
            height: calc( 100vh - 66px );
        }
    }
}


@include media-breakpoint-down(md) {
    .section-hero{
        .section-hero-content {
            h2 {
                font-size: 26px;
                padding: 0 10px;
            }
            p {
                font-size: 16px;
            }
            .hero-icon {
                font-size: 56px;
            }
        }
    }
    // section
    .section {
        .section-wrapper{
            padding-left: 0;
        }
        .section-text{
            margin: 25px 0;
            p {
                padding-left: 0px;
                width: 100%;
                max-width: 100%;
            }
            h6{
                width: 100%;
                margin-bottom: 25px;
            }
        }
    }

    .pull{
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    // section hero
    .section-hero .page-hero{
        padding-bottom: 112.5%;
    }

    // section about
    .section-about{
        .section-about-bg{
            display: none;
        }
        .section-wrapper{
            padding-left: 0;
        }
        .boxed{
            margin: 0;
            padding: 0;
        }
    }

    // section parallax
    .section-parallax{
        margin-bottom: 0;
        ul.list-parallax{
            display: block;
        }
    }
    .parallax{
        padding-top: 50px;
    }

    // section bundle
    .section-bundle {
        .swiper-nav-prev, .swiper-nav-next{
            top: 160px;
        }
        .swiper-nav-next{
            right: 0;
            transform: translateX(0px);
            left: auto;
        }
    }

    #swiper-bundle {
        &:before, &:after{
            content: none;
        }
        .swiper-slide {
            width: 100%;
            .image-holder {
                opacity: 1;
                height: 400px;
                margin-left: 0;
            }
            .boxed-wrapper {
                width: 100%;
                padding: 0px;
                margin-top: 0px;
                opacity: 1;
                visibility:visible;
                transform: translateX(0px);
                .boxed{
                    transform: translateY(0px);
                }
            }
            ul.list-base{
                margin-top: 30px;
            }
        }
        .swiper-slide-next{
            .image-holder {
                width: 100%;
                margin-left: 0;
            }
        }

        .swiper-slide-active {
            width: 100%;
            .image-holder {
                width: 100%;
                margin-left: 0;
            }
        }

        .boxed{
            box-shadow: none;
            background-color: $gray-100;
        }
    }

    // section events
    .section-events{
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        .section-text{
            padding-top: 0px;
        }
    }

    // section food
    .section-food{
        .content-bg:before{
            bottom: 0;
        }
    }
}


@include media-breakpoint-down(sm) {
    // section
    .section {
        margin: 40px 0;
        .section-title{
            margin: 20px 0;
        }
    }

    // section about
    .section-about{
        padding: 0;
    }

    // section parallax
    .section-parallax{
        margin-bottom: 0;
    }

    // section events
    .section-events{
        margin-top: 0;
    }

    // section history
    .section-history{
        margin-bottom: 0;
    }
    .hero-history{
        min-height: auto;
        padding: 80px 0;
        .hero-content{
            p, .boxed{
                width: 100%;
                max-width: 100%;
            }
        }
    }
}