@font-face {
    font-family: 'icomoon';
    src: url(../fonts/icomoon.eot?1cobi);
    src: url(../fonts/icomoon.eot?1cobi#iefix) format('embedded-opentype'), url(../fonts/icomoon.ttf?1cobi) format('truetype'), url(../fonts/icomoon.woff?1cobi) format('woff'), url(../fonts/icomoon.svg?1cobi#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-calendar:before {
    content: "\e938";
  }
  .icon-arrow-bottom-sm:before {
    content: "\e900";
  }
  .icon-arrow-down-sm:before {
    content: "\e901";
  }
  .icon-arrow-down:before {
    content: "\e902";
  }
  .icon-arrow-left-sm:before {
    content: "\e903";
  }
  .icon-arrow-left:before {
    content: "\e904";
  }
  .icon-arrow-right-sm:before {
    content: "\e905";
  }
  .icon-arrow-right:before {
    content: "\e906";
  }
  .icon-arrow-up-sm:before {
    content: "\e907";
  }
  .icon-arrow-up:before {
    content: "\e908";
  }
  .icon-booking:before {
    content: "\e909";
  }
  .icon-central-hotel:before {
    content: "\e90a";
  }
  .icon-central:before {
    content: "\e90b";
  }
  .icon-close:before {
    content: "\e90c";
  }
  .icon-coffee-machine:before {
    content: "\e90d";
  }
  .icon-cosmetics:before {
    content: "\e90e";
  }
  .icon-covering:before {
    content: "\e90f";
  }
  .icon-cross-out:before {
    content: "\e910";
  }
  .icon-day-light:before {
    content: "\e911";
  }
  .icon-desk:before {
    content: "\e912";
  }
  .icon-down-arrow:before {
    content: "\e913";
  }
  .icon-download:before {
    content: "\e914";
  }
  .icon-eclipse:before {
    content: "\e915";
  }
  .icon-envelope:before {
    content: "\e916";
  }
  .icon-eye:before {
    content: "\e917";
  }
  .icon-facebook:before {
    content: "\e918";
  }
  .icon-flipchart:before {
    content: "\e919";
  }
  .icon-hair-dryer:before {
    content: "\e91a";
  }
  .icon-instagram:before {
    content: "\e91b";
  }
  .icon-laser:before {
    content: "\e91c";
  }
  .icon-loudspeaker:before {
    content: "\e91d";
  }
  .icon-magnifying-glass:before {
    content: "\e91e";
  }
  .icon-minibar:before {
    content: "\e91f";
  }
  .icon-monitor:before {
    content: "\e920";
  }
  .icon-network-tv:before {
    content: "\e921";
  }
  .icon-pin:before {
    content: "\e922";
  }
  .icon-presentation:before {
    content: "\e923";
  }
  .icon-safe:before {
    content: "\e924";
  }
  .icon-search:before {
    content: "\e92d";
  }
  .icon-shower:before {
    content: "\e92e";
  }
  .icon-smartphone-handy:before {
    content: "\e92f";
  }
  .icon-smartphone:before {
    content: "\e930";
  }
  .icon-television:before {
    content: "\e931";
  }
  .icon-tripadvisor:before {
    content: "\e932";
  }
  .icon-wardrobe:before {
    content: "\e933";
  }
  .icon-welcome-gf:before {
    content: "\e934";
  }
  .icon-wifi:before {
    content: "\e935";
  }
  .icon-youtube-logo:before {
    content: "\e936";
  }
  .icon-youtube:before {
    content: "\e937";
  }
  .icon-files-empty:before {
    content: "\e925";
  }
  .icon-file-text2:before {
    content: "\e926";
  }
  .icon-file-picture:before {
    content: "\e927";
  }
  .icon-file-music:before {
    content: "\e928";
  }
  .icon-file-play:before {
    content: "\e929";
  }
  .icon-file-video:before {
    content: "\e92a";
  }
  .icon-file-zip:before {
    content: "\e92b";
  }
  .icon-copy:before {
    content: "\e92c";
  }
  .icon-link:before {
    content: "\e9cb";
  }
  .icon-plus:before {
    content: "\ea0a";
  }
  .icon-minus:before {
    content: "\ea0b";
  }
  .icon-cross:before {
    content: "\ea0f";
  }
  .icon-checkmark:before {
    content: "\ea10";
  }
  .icon-google:before {
    content: "\ea88";
  }
  .icon-google2:before {
    content: "\ea89";
  }
  .icon-google3:before {
    content: "\ea8a";
  }
  .icon-google-plus:before {
    content: "\ea8b";
  }
  .icon-facebook1:before {
    content: "\ea90";
  }
  .icon-facebook2:before {
    content: "\ea91";
  }
  .icon-instagram1:before {
    content: "\ea92";
  }
  .icon-twitter:before {
    content: "\ea96";
  }
  .icon-youtube1:before {
    content: "\ea9d";
  }
  .icon-youtube2:before {
    content: "\ea9e";
  }
  .icon-vimeo:before {
    content: "\eaa0";
  }
  .icon-vimeo2:before {
    content: "\eaa1";
  }
  .icon-linkedin:before {
    content: "\eac9";
  }
  .icon-linkedin2:before {
    content: "\eaca";
  }
  .icon-pinterest:before {
    content: "\ead1";
  }
  .icon-pinterest2:before {
    content: "\ead2";
  }
  .icon-file-pdf:before {
    content: "\eadf";
  }
  .icon-file-openoffice:before {
    content: "\eae0";
  }
  .icon-file-word:before {
    content: "\eae1";
  }
  .icon-file-excel:before {
    content: "\eae2";
  }
  