//
// Offcanvas
//
.offcanvas {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0;
    bottom: 0px;
    background-color: $white;
    z-index: 99;
    transform: translate3d(-100%, 0, 0);
    transition: all .4s;
    padding: 40px 20px;
    padding-top: 90px;
    overflow-y: auto;
    ul.offcanvas-nav{
        li{
            margin-bottom: 5px;
        }
        li a{
            display: block;
        }
    }

    .dropdown-menu{
        position: static;
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
        width: 100%;
        border:0px;
        padding-left: 25px;
        margin: 10px 0;
    }
    .dropdown-item{
        border:0px;
        padding: 0;
        font-size: 13px;
    }
}

.offcanvas-data{
    p{
        margin: 0;
        font-size: 14px;
    }
    .data-group + .data-group{
        margin-top: 20px;
    }

    .data-group {
        img {
            max-width: 75px;
            margin: 10px 0;
        }
    }
}

ul.offcanvas-lang{
    display: flex;
    li{
        padding: 2px 4px;
    }
    li:first-child{
        padding-left: 0;
    }
}

body.menu-is-active{
    overflow-y: hidden;
    #offcanvas {
        transform: translate3d(0, 0, 0);
    }
}