// 
// Hero
// 

.page-hero-wrapper{
    position: relative;
}
.page-hero {
    position: relative;
    padding-bottom: 36.5%;
    overflow: hidden;
    .page-hero-background {
        position: absolute;
        z-index: 44;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover; //background-attachment: fixed;
        background-position: 50%;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba( $dark, 0.4);
        }
    }
}

.hero-lg{
    padding-bottom: 42.5%;
}

.hero-sm {
    padding-bottom: 27%;
}


@include media-breakpoint-down(md) {
    .page-hero {
        padding-bottom: 62.5%;
    }
}