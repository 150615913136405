//
// Footer
//
.footer {
    padding: 50px 0;
    text-align: center;
    font-size: 14px;
    overflow: hidden;
    .footer-row {
        display: inline-block;
        padding: 0 20px;
    }
    .footer-brand {
        text-align: center;
        margin-bottom: 20px;
        img {
            max-width: 180px;
        }
    }
    ul.footer-nav {
        margin: 0;
        display: flex;
        padding: 20px 40px;
        border-bottom: 1px solid #d9d9d9;
        justify-content: space-between;
        li {
            margin: 0 20px;
            min-width: 0px;
        }
        li:first-child {
            margin-left: 0;
        }
        li:last-child {
            margin-right: 0;
        }
        li a {
            font-size: inherit;
            color: rgba( $text-color, 0.6);
            white-space: nowrap;
            &:hover {
                color: $dark;
            }
        }
    }
    ul.footer-data {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        padding: 20px 40px;
        li {
            white-space: nowrap;
            font-size: inherit;
            display: block;
        }
        li + li{
            margin-left: 15px;
        }
        span {
            color: rgba( $text-color, 0.6);
        }
    }
    ul.footer-socials {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        li {
            padding: 10px;
            transition: all 0.35s;
        }
        li a {
            font-size: 16px;
        }
    }
    .footer-group {
        position: relative;
        ul.footer-socials {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(260%, -50%);
        }
    }
    .copyright {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
        p,
        a {
            font-size: inherit;
            margin: 0;
        }
        a {
            color: rgba( $text-color, 0.6);
            margin-left: 10px;
            &:hover {
                color: $dark;
            }
        }
    }
}

.footer-newsletter {
    border-top: 1px solid #d9d9d9;
    padding-top: 25px;
    margin-top: 25px;

    .h4, p form, input, button {
        margin: 0;
    }

    .newsletter-row {
        display: flex;
        align-items: center;
    }

    .newsletter-title {
        margin-right: 60px;
    }

    .newsletter-text {
        max-width: 450px;
        text-align: left;
        padding-right: 20px;
        p {
            font-size: 13px;
            margin: 0;
        }
    }

    .newsletter-form {
        margin-left: auto;
        form {
            display: flex;
            border:1px solid #d9d9d9;
        }

        button, .form-control {
            height: 52px;
            border:0px
        }

        button {
            background-color: #f0f0ef;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
            white-space: nowrap;
            padding: 0 20px;
            cursor: pointer;

            &:hover {
                color: $primary;
            }
        }

        .form-control {
            padding-left: 15px;
            width: 300px;
        }
    }
}

.footter-eu {
    border-top: 1px solid #d9d9d9;
    text-align: center;
    padding-top: 25px;
    margin-top: 25px;

    img {
        margin: 10px 0;
    }
}

@include media-breakpoint-down(xl) {
    .footer {
        .footer-group {
            ul.footer-socials {
                transform: translate(150%, -50%);
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .footer {
        .footer-group {
            ul.footer-socials {
                position: static;
                transform: translate(0, 0);
            }
        }

        ul.footer-nav, ul.footer-data{
            padding: 15px 0px;
        }
    }

    .footer-newsletter {
        .newsletter-title {
            margin-right: 20px;
            padding-right: 25px;
        }

        .form-control {
            width: auto;
        }
    }
}

@include media-breakpoint-down(md) {
    .footer {
        ul.footer-data li,
        ul.footer-nav li a {
            white-space: normal;
        }
        ul.footer-data,
        ul.footer-nav {
            display: block;
        }
        ul.footer-nav li {
            margin: 5px 0;
        }
        .copyright {
            display: block;
        }

        ul.footer-data {
            li + li{
                margin-left: 0px;
            }
            span {
                color: rgba( $text-color, 0.6);
            }
        }
    }

    .footer-newsletter {
        .newsletter-row {
            display: block;
            text-align: center;
        }

        .newsletter-title {
            padding: 0;
            margin: 10px 0;
        }

        .newsletter-text{
            margin: 10px 0;
            padding: 0;
            text-align: center;
            max-width: 100%;
        }

        .form-control {
            padding-left: 10px;
            padding-right: 10px;
            flex-grow: 1;
        }

        button {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

@include media-breakpoint-down(sm) {}