// 
// list history
// 

.history-title{
    text-transform: uppercase;
    margin: 40px 0;
    margin-top: 80px;
}

.history-item-text{
    text-align: center;
    width: 850px;
    margin: 55px auto;
    max-width: 90%;
}

.history-item-image{
    display: flex;
    padding-top: 20px;
    padding-bottom: 50px;
    align-items: center;
    margin-left: 8.33333%;
    .history-image{
        flex: 0 0 33.33333%;
    }
    .history-content{
        flex: 0 0 41.66667%;
        margin-left: 8.33333%;
        h3, h4, h5, h6{
            margin-bottom: 40px;
        }
        p{
            margin: 0;
        }
    }

}

.history-item-image.image-right{
    .history-image{
        order: 2;
        margin-left: 8.33333%;
    }
    .history-content{
        order: 1;
    }
}

ul.list-history{
    li{
        padding-top: 90px;
        position: relative;
        &:before{
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            width: 1px;
            height: 80px;
            background-color: $gray-300;
        }
    }
}


@include media-breakpoint-down(sm) {
    .history-title{
        margin-top: 20px;
        margin-bottom: 0;
    }
    .history-item-text{
        margin: 30px auto;
    }
    .history-item-image{
        flex-wrap: wrap;
        padding: 20px 0;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        .history-image{
            flex: 0 0 100%;
            margin-bottom: 40px;
        }
        .history-content{
            flex: 0 0 100%;
            margin-left: 0;
            margin-right: 0;
            h3, h4, h5, h6{
                margin-bottom: 20px;
            }
            p{
                margin-bottom: 20px;
            }
        }
    }

    .history-item-image.image-right{
        .history-image{
            order: 1;
            margin-left: 0;
            margin-right: 0;
        }
        .history-content{
            order: 2;
            margin-left: 0;
            margin-right: 0;
        }
    }

    ul.list-history li{
        padding-top: 60px;
        &:before{
            height: 50px;
        }
    }
}