// 
// Boxed
// 
.boxed {
    background-color: $white;
    padding: 50px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    .btn {
        padding-left: 0;
    }
}

@include media-breakpoint-down(md) {
    .boxed {
        padding: 25px;
    }
}